unit Unit1;

interface

uses
  SysUtils, Classes, JS, Web, WEBLib.Graphics, WEBLib.Controls,
  WEBLib.Forms, WEBLib.Dialogs, WEBLib.ExtCtrls, WEBLib.StdCtrls,
  WEBLib.ComCtrls, WEBLib.Buttons, WEBLib.GoogleChart,
  WEBLib.Imaging.jpeg, WEBLib.ClientConnector, WEBLib.CDS, WEBLib.Slider,
  WEBLib.WebCtrls;

type
  TForm1 = class(TForm)
    Basis: TPanel;
    TopPaneel: TPanel;
    BodemPaneel: TPanel;
    HoofdPaneel: TPanel;
    HoofdMenu: TPageControl;
    Werkzaamheden: TTabSheet;
    Projecten: TTabSheet;
    Contact: TTabSheet;
    CopyRight: TLabel;
    Home: TTabSheet;
    HomePanel: TPanel;
    AlgemeenContainer: THTMLContainer;
    DienstenPanel: TPanel;
    DienstenContainer: THTMLContainer;
    ProjectenPanel: TPanel;
    ContactPaneel: TPanel;
    ContactContainer: THTMLContainer;
    Plattegrond: TImageControl;
    WebTimer: TTimer;
    WebClientConnection1: TClientConnection;
    Logo: TImageControl;
    ImageSlider: TImageSlider;
    WebPanel1: TPanel;
    LaadFotos: TButton;
    CategorieKiezer: TComboBox;
    EffectKiezer: TComboBox;
    NavigatieKiezer: TComboBox;
    CategorieLBL: TLabel;
    EffectLBL: TLabel;
    NavigatieLBL: TLabel;
    WebMemo: TMemo;
    DeBus: TImageControl;
    WebLabel1: TLabel;
    WebLabel2: TLabel;
    WebLabel3: TLabel;
    WebLabel4: TLabel;
    WebLabel5: TLabel;
    WebLabel6: TLabel;
    WebLabel7: TLabel;
    WebLabel8: TLabel;
    WebLabel9: TLabel;    procedure WebFormCreate(Sender: TObject);
    procedure InloggenClick(Sender: TObject);
    //procedure ControleClick(Sender: TObject);
    procedure DownloadLinkClick(Sender: TObject);
    procedure WebFormClose(Sender: TObject; var Action: TCloseAction);
    procedure TestenClick(Sender: TObject);
    procedure LaadFotosClick(Sender: TObject);
    procedure CategorieKiezerChange(Sender: TObject);
    procedure EffectKiezerChange(Sender: TObject);
    procedure NavigatieKiezerChange(Sender: TObject);
    procedure HoofdMenuChange(Sender: TObject);
  private
    procedure DoOnImageClick(Sender: TObject; ImageIndex: integer);
    procedure DoOnThumbnailClick(Sender: TObject; ImageIndex: integer);
    procedure DoOnImageChange(Sender: TObject; ImageIndex: integer);
    procedure EnableProperButtons;
    { Private declarations }
  public
    { Public declarations }
  protected procedure LoadDFMValues; override; end;

var
  Form1: TForm1;

implementation

{$R *.dfm}

{
procedure TForm1.ControleClick(Sender: TObject);
Var Teller, Wisser, zoeker, Regel: Integer;
    Gevonden: Boolean;
begin
 WebMemo.Lines.Clear;

 try
  WebMemo.Lines.LoadFromFile('Klanten.txt');
 finally
 end;

 For Wisser := WebMemo.Lines.Count - 1 DownTo 0 Do If WebMemo.Lines[Wisser] = '' Then WebMemo.Lines.Delete(Wisser);

 //Controleren op geregistreerde gebruiker...
 Teller := 0;
 WebTimer.Enabled := True;

 Gevonden := False;
 Regel := -1;

 If (Inlogcode.Text <> '') And (WebMemo.Lines.Count > 0) Then
 Begin
  For zoeker := 0 To WebMemo.Lines.Count - 1 Do
  Begin
   If Pos(Copy(WebMemo.Lines[zoeker], 1, 50), Inlogcode.Text) <> 0 Then Begin Gevonden := True; Regel := zoeker; End;
  End;
  If (Regel <> -1) And (Gevonden = True) Then
  Begin
   DownloadLink.Caption := '<a href="' + Copy(WebMemo.Lines[Regel], 54, Length(WebMemo.Lines[Regel]) - 53) + '">Download de Applicatie</a>';
   DownloadLink.Visible := True;
   Webtimer.Enabled := False;
   WebTimer.Interval := 1000;
  End
  Else
   Begin
   Inc(Teller);
   WebTimer.Interval := Teller * Teller * 1000;
   Inlogcode.Visible := False;
   Inlogcode.Text := '';
   Controle.Visible := False;
   DownloadLink.Visible := False;
  End;
 End
 Else
 Begin
  Inlogcode.Visible := False;
  Inlogcode.Text := '';
  Controle.Visible := False;
  DownloadLink.Visible := False;
 End;

end;
}

procedure TForm1.DownloadLinkClick(Sender: TObject);
begin
{
  Inlogcode.Visible := False;
  Controle.Visible := False;
  Inlogcode.Text := '';
  DownloadLink.Visible := False;
}
end;

procedure TForm1.InloggenClick(Sender: TObject);
begin
{
 Inlogcode.SetFocus;
 Inlogcode.Visible := True;
 Controle.Visible := True;
}
end;

procedure TForm1.WebFormClose(Sender: TObject; var Action: TCloseAction);
begin
 //
end;

procedure TForm1.WebFormCreate(Sender: TObject);
Var Wisser: Integer;
begin
  If (Form1.Width > 1200) Then
  Basis.Left := (Form1.Width - Basis.Width) Div 2 Else
  Basis.Left := 0;
  //Op een smartphone wordt er door het kantelen een lege ruimte gemaakt onder de TabControl
  //Dat is nu een beetje ondervangen met onderstaande code...
  HoofdMenu.BeginUpdate;
  //HoofdMenu.Height := RechterPaneel.Height;
  HoofdMenu.EndUpdate;
  HoofdMenu.TabIndex := 0;

  ImageSlider.Color := clWhite;
  ImageSlider.Appearance.Buttons.Color := clNavy;
  ImageSlider.Appearance.Buttons.Visible := False;

  ImageSlider.OnImageClick := DoOnImageClick;
  ImageSlider.OnThumbnailClick := DoOnThumbnailClick;
  ImageSlider.OnImageChange := DoOnImageChange;

  CategorieKiezer.Items.Add('Keukens & Sanitair');
  CategorieKiezer.Items.Add('Interne verbouwingen');
  CategorieKiezer.Items.Add('Ramen & Deuren');
  CategorieKiezer.Items.Add('Pergola''s');
  CategorieKiezer.Items.Add('Bergingen');
  CategorieKiezer.Items.Add('Tuinhuizen');
  CategorieKiezer.Items.Add('Overkappingen');
  CategorieKiezer.Items.Add('Schuttingen & Poorten');
  CategorieKiezer.Items.Add('Sierstraatwerk');
  CategorieKiezer.Items.Add('Zonweringen');
  CategorieKiezer.Items.Add('Rolluiken');
  CategorieKiezer.Items.Add('Balustrades');
  //CategorieKiezer.Items.Add('');
  CategorieKiezer.ItemIndex := 0;

  EffectKiezer.Items.Add('Schuiven');
  EffectKiezer.Items.Add('Vloeien');
  EffectKiezer.Items.Add('Omdraaien');
  EffectKiezer.ItemIndex := 2;
  EffectKiezerChange(Self);
  //EffectKiezer.ItemIndex := Integer(ImageSlider.Appearance.TransitionEffect);

  NavigatieKiezer.Items.Add('Geen');
  NavigatieKiezer.Items.Add('Miniaturen');
  NavigatieKiezer.Items.Add('Ballen');
  NavigatieKiezer.ItemIndex := 2;
  NavigatieKiezerChange(Self);
  //NavigatieKiezer.ItemIndex := Integer(ImageSlider.Appearance.NavigationStyle);
  EnableProperButtons;

  WebMemo.Lines.Clear;

  try
   WebMemo.Lines.LoadFromFile('Aantallen.txt');
  finally
  end;
  For Wisser := WebMemo.Lines.Count - 1 DownTo 0 Do If WebMemo.Lines[Wisser] = '' Then WebMemo.Lines.Delete(Wisser);

  //LaadFotosClick(Self);
End;

Procedure TForm1.LaadFotosClick(Sender: TObject);
Var i, Aantal, Plek, loper: Integer;
    Categor: String;
    EenLege: Boolean;
Begin

 Case CategorieKiezer.ItemIndex Of
 0  : Categor := 'KeukensSanitair';
 1  : Categor := 'InterneVerbouwingen';
 2  : Categor := 'RamenDeuren';
 3  : Categor := 'Pergolas';
 4  : Categor := 'Bergingen';
 5  : Categor := 'Tuinhuizen';
 6  : Categor := 'Overkappingen';
 7  : Categor := 'SchuttingenPoorten';
 8  : Categor := 'Sierstraatwerk';
 9  : Categor := 'Zonweringen';
 10 : Categor := 'Rolluiken';
 11 : Categor := 'Balustrades';
 //12 : Categor := '';
 End; {Case}

 ImageSlider.ImageURLs.Clear;
 ImageSlider.Appearance.Buttons.Visible := False;
 ImageSlider.Appearance.Bullets.Size := 0;

 Plek := Pos(' |', WebMemo.Lines[CategorieKiezer.ItemIndex]);
 If Plek > 0 Then
 Begin
  Aantal := StrToInt(Copy(WebMemo.Lines[CategorieKiezer.ItemIndex], Plek + 3, Length(WebMemo.Lines[CategorieKiezer.ItemIndex])- Plek - 1));
 End;

 If Aantal > 0 Then
 Begin
  For i := 1 To Aantal Do ImageSlider.ImageURLs.Add(Format('./Fotos/' + Categor + '/' + Categor + '-%d.jpg', [i]));
  ImageSlider.Appearance.Buttons.Visible := True;
  ImageSlider.Appearance.Bullets.Size := 18;
  ImageSlider.RefreshImages;
 End;

 ImageSlider.RefreshImages;
End;

procedure TForm1.DoOnImageClick(Sender: TObject; ImageIndex: integer);
begin
  //memoEventInfo.Text := Format('Event: ImageClick'#13#10 + 'Index: %d',[ImageIndex]);
end;

procedure TForm1.DoOnThumbnailClick(Sender: TObject; ImageIndex: integer);
begin
  //memoEventInfo.Text := Format('Event: ThumbnailClick'#13#10 + 'Index: %d',[ImageIndex]);
end;

procedure TForm1.DoOnImageChange(Sender: TObject; ImageIndex: integer);
begin
  //memoEventInfo.Text := Format('Event: ImageChange'#13#10 + 'Index: %d',[ImageIndex]);
end;


procedure TForm1.CategorieKiezerChange(Sender: TObject);
begin
 LaadFotosClick(Self);
 ImageSlider.Appearance.TransitionEffect := TTransitionEffect(EffectKiezer.ItemIndex);
 EnableProperButtons;
end;

procedure TForm1.EffectKiezerChange(Sender: TObject);
begin
 ImageSlider.Appearance.TransitionEffect := TTransitionEffect(EffectKiezer.ItemIndex);
 EnableProperButtons;
end;

procedure TForm1.NavigatieKiezerChange(Sender: TObject);
begin
 ImageSlider.Appearance.NavigationStyle := TNavigationStyle(NavigatieKiezer.ItemIndex);

 If NavigatieKiezer.ItemIndex = 1 Then ImageSlider.Height := 465 Else ImageSlider.Height := 372;
 EnableProperButtons;
end;

procedure TForm1.EnableProperButtons;
begin
  //LaadFotos.Enabled := ImageSlider.ImageUrls.Count = 0;
  //CategorieKiezer.Enabled := ImageSlider.ImageUrls.Count > 0;
  //NavigatieKiezer.Enabled := ImageSlider.ImageUrls.Count > 0;
  //EffectKiezer.Enabled := ImageSlider.ImageUrls.Count > 0;


  //btChangeHeight.Enabled := (ImageSlider.ImageUrls.Count > 0) and(ImageSlider.Appearance.NavigationStyle = navsThumbnails);
  //btChangeCount.Enabled := btChangeHeight.Enabled;
  //ckSizeAspectRatio.Enabled := btChangeHeight.Enabled;
end;

procedure TForm1.HoofdMenuChange(Sender: TObject);
begin
 If HoofdMenu.TabIndex = 2 Then LaadFotosClick(Self);
end;

procedure TForm1.TestenClick(Sender: TObject);
begin

// Basics/Dataset

   // empty dataset for testing https://download.tmssoftware.com/tmsweb/fishfacti2_empty.json
{
  WebClientConnection1.URI := 'https://download.tmssoftware.com/tmsweb/fishfacti.json';
  WebClientConnection1.DataNode := 'ROW';
}
//  WebClientDataSet1.FieldDefs.Clear;
//  WebClientDataSet1.FieldDefs.Add('_Species_No',ftString,0);
//  WebClientDataSet1.FieldDefs.Add('_Category',ftstring,50);
//  WebClientDataSet1.FieldDefs.Add('_Common_Name',ftstring,50);
//  WebClientDataSet1.FieldDefs.Add('_Species_Name',ftstring,50);
//  WebClientDataSet1.FieldDefs.Add('_Length__cm_',ftInteger,0);
//  WebClientDataSet1.FieldDefs.Add('_Length_In',ftString,30);
//  WebClientDataSet1.FieldDefs.Add('_Notes',ftString,255);
{
  WebClientConnection1.Active := true;
  WebButton1.Enabled := false;
}

end;


{

Examples  Services/Simple

procedure TForm1.WebButton1Click(Sender: TObject);
begin
  WebHttpRequest1.URL := 'https://download.tmssoftware.com/tmsweb/music.json';

  WebHttpRequest1.Execute(

      procedure(AResponse: string; AReq: TJSXMLHttpRequest)
      var
        js: TJSON;
        ja: TJSONArray;
        jo: TJSONObject;
        i: integer;
      begin
        js := TJSON.Create;

        try
          ja := TJSONArray(js.Parse(AResponse));

          ShowMessage('Retrieved items:' +inttostr(ja.Count));

          for i := 0 to ja.Count - 1 do
          begin
            jo := TJSONObject(ja.Items[i]);
            WebListBox1.Items.Add(jo.GetJSONValue('title'));
          end;
        finally
          js.Free;
        end;
      end
  );
end;

}

procedure TForm1.LoadDFMValues;
begin
  inherited LoadDFMValues;

  Basis := TPanel.Create(Self);
  TopPaneel := TPanel.Create(Self);
  Logo := TImageControl.Create(Self);
  DeBus := TImageControl.Create(Self);
  WebLabel1 := TLabel.Create(Self);
  WebLabel2 := TLabel.Create(Self);
  WebLabel3 := TLabel.Create(Self);
  WebLabel4 := TLabel.Create(Self);
  WebLabel5 := TLabel.Create(Self);
  WebLabel6 := TLabel.Create(Self);
  WebLabel7 := TLabel.Create(Self);
  WebLabel8 := TLabel.Create(Self);
  HoofdPaneel := TPanel.Create(Self);
  HoofdMenu := TPageControl.Create(Self);
  Home := TTabSheet.Create(Self);
  HomePanel := TPanel.Create(Self);
  AlgemeenContainer := THTMLContainer.Create(Self);
  Werkzaamheden := TTabSheet.Create(Self);
  DienstenPanel := TPanel.Create(Self);
  DienstenContainer := THTMLContainer.Create(Self);
  Projecten := TTabSheet.Create(Self);
  ProjectenPanel := TPanel.Create(Self);
  ImageSlider := TImageSlider.Create(Self);
  WebPanel1 := TPanel.Create(Self);
  CategorieLBL := TLabel.Create(Self);
  EffectLBL := TLabel.Create(Self);
  NavigatieLBL := TLabel.Create(Self);
  LaadFotos := TButton.Create(Self);
  CategorieKiezer := TComboBox.Create(Self);
  EffectKiezer := TComboBox.Create(Self);
  NavigatieKiezer := TComboBox.Create(Self);
  WebMemo := TMemo.Create(Self);
  Contact := TTabSheet.Create(Self);
  ContactPaneel := TPanel.Create(Self);
  Plattegrond := TImageControl.Create(Self);
  ContactContainer := THTMLContainer.Create(Self);
  BodemPaneel := TPanel.Create(Self);
  CopyRight := TLabel.Create(Self);
  WebLabel9 := TLabel.Create(Self);
  WebTimer := TTimer.Create(Self);
  WebClientConnection1 := TClientConnection.Create(Self);

  Basis.BeforeLoadDFMValues;
  TopPaneel.BeforeLoadDFMValues;
  Logo.BeforeLoadDFMValues;
  DeBus.BeforeLoadDFMValues;
  WebLabel1.BeforeLoadDFMValues;
  WebLabel2.BeforeLoadDFMValues;
  WebLabel3.BeforeLoadDFMValues;
  WebLabel4.BeforeLoadDFMValues;
  WebLabel5.BeforeLoadDFMValues;
  WebLabel6.BeforeLoadDFMValues;
  WebLabel7.BeforeLoadDFMValues;
  WebLabel8.BeforeLoadDFMValues;
  HoofdPaneel.BeforeLoadDFMValues;
  HoofdMenu.BeforeLoadDFMValues;
  Home.BeforeLoadDFMValues;
  HomePanel.BeforeLoadDFMValues;
  AlgemeenContainer.BeforeLoadDFMValues;
  Werkzaamheden.BeforeLoadDFMValues;
  DienstenPanel.BeforeLoadDFMValues;
  DienstenContainer.BeforeLoadDFMValues;
  Projecten.BeforeLoadDFMValues;
  ProjectenPanel.BeforeLoadDFMValues;
  ImageSlider.BeforeLoadDFMValues;
  WebPanel1.BeforeLoadDFMValues;
  CategorieLBL.BeforeLoadDFMValues;
  EffectLBL.BeforeLoadDFMValues;
  NavigatieLBL.BeforeLoadDFMValues;
  LaadFotos.BeforeLoadDFMValues;
  CategorieKiezer.BeforeLoadDFMValues;
  EffectKiezer.BeforeLoadDFMValues;
  NavigatieKiezer.BeforeLoadDFMValues;
  WebMemo.BeforeLoadDFMValues;
  Contact.BeforeLoadDFMValues;
  ContactPaneel.BeforeLoadDFMValues;
  Plattegrond.BeforeLoadDFMValues;
  ContactContainer.BeforeLoadDFMValues;
  BodemPaneel.BeforeLoadDFMValues;
  CopyRight.BeforeLoadDFMValues;
  WebLabel9.BeforeLoadDFMValues;
  WebTimer.BeforeLoadDFMValues;
  WebClientConnection1.BeforeLoadDFMValues;
  try
    Name := 'Form1';
    Width := 950;
    Height := 1000;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -11;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnClose', 'WebFormClose');
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    Basis.SetParentComponent(Self);
    Basis.Name := 'Basis';
    Basis.Left := -8;
    Basis.Top := 0;
    Basis.Width := 950;
    Basis.Height := 1000;
    Basis.ElementClassName := 'basispanel';
    Basis.HeightPercent := 100.000000000000000000;
    Basis.WidthPercent := 100.000000000000000000;
    Basis.BorderColor := clSilver;
    Basis.BorderStyle := bsSingle;
    Basis.Color := clWhite;
    TopPaneel.SetParentComponent(Basis);
    TopPaneel.Name := 'TopPaneel';
    TopPaneel.Left := 0;
    TopPaneel.Top := 0;
    TopPaneel.Width := 945;
    TopPaneel.Height := 181;
    TopPaneel.HeightPercent := 100.000000000000000000;
    TopPaneel.WidthPercent := 100.000000000000000000;
    TopPaneel.BorderColor := clSilver;
    TopPaneel.BorderStyle := bsSingle;
    TopPaneel.ChildOrder := 1;
    TopPaneel.Color := clWhite;
    Logo.SetParentComponent(TopPaneel);
    Logo.Name := 'Logo';
    Logo.Left := 5;
    Logo.Top := 19;
    Logo.Width := 256;
    Logo.Height := 94;
    Logo.HeightPercent := 100.000000000000000000;
    Logo.WidthPercent := 100.000000000000000000;
    Logo.ChildOrder := 2;
    Logo.Picture.LoadFromFile('Unit1.TopPaneel.Logo.Picture.jpg');
    DeBus.SetParentComponent(TopPaneel);
    DeBus.Name := 'DeBus';
    DeBus.Left := 630;
    DeBus.Top := 8;
    DeBus.Width := 300;
    DeBus.Height := 150;
    DeBus.HeightPercent := 100.000000000000000000;
    DeBus.WidthPercent := 100.000000000000000000;
    DeBus.ChildOrder := 1;
    DeBus.Picture.LoadFromFile('Unit1.TopPaneel.DeBus.Picture.jpg');
    WebLabel1.SetParentComponent(TopPaneel);
    WebLabel1.Name := 'WebLabel1';
    WebLabel1.Left := 272;
    WebLabel1.Top := 15;
    WebLabel1.Width := 132;
    WebLabel1.Height := 19;
    WebLabel1.Caption := 'Wij denken mee';
    WebLabel1.Font.Charset := DEFAULT_CHARSET;
    WebLabel1.Font.Color := 4587520;
    WebLabel1.Font.Height := -16;
    WebLabel1.Font.Name := 'Tahoma';
    WebLabel1.Font.Style := [fsBold,fsItalic];
    WebLabel1.HeightPercent := 100.000000000000000000;
    WebLabel1.ParentFont := False;
    WebLabel1.WidthPercent := 100.000000000000000000;
    WebLabel2.SetParentComponent(TopPaneel);
    WebLabel2.Name := 'WebLabel2';
    WebLabel2.Left := 318;
    WebLabel2.Top := 40;
    WebLabel2.Width := 81;
    WebLabel2.Height := 19;
    WebLabel2.Caption := 'Adviseren';
    WebLabel2.Font.Charset := DEFAULT_CHARSET;
    WebLabel2.Font.Color := 4587520;
    WebLabel2.Font.Height := -16;
    WebLabel2.Font.Name := 'Tahoma';
    WebLabel2.Font.Style := [fsBold,fsItalic];
    WebLabel2.HeightPercent := 100.000000000000000000;
    WebLabel2.ParentFont := False;
    WebLabel2.WidthPercent := 100.000000000000000000;
    WebLabel3.SetParentComponent(TopPaneel);
    WebLabel3.Name := 'WebLabel3';
    WebLabel3.Left := 355;
    WebLabel3.Top := 65;
    WebLabel3.Width := 90;
    WebLabel3.Height := 19;
    WebLabel3.Caption := 'Ontwerpen';
    WebLabel3.Font.Charset := DEFAULT_CHARSET;
    WebLabel3.Font.Color := 4587520;
    WebLabel3.Font.Height := -16;
    WebLabel3.Font.Name := 'Tahoma';
    WebLabel3.Font.Style := [fsBold,fsItalic];
    WebLabel3.HeightPercent := 100.000000000000000000;
    WebLabel3.ParentFont := False;
    WebLabel3.WidthPercent := 100.000000000000000000;
    WebLabel4.SetParentComponent(TopPaneel);
    WebLabel4.Name := 'WebLabel4';
    WebLabel4.Left := 392;
    WebLabel4.Top := 90;
    WebLabel4.Width := 71;
    WebLabel4.Height := 19;
    WebLabel4.Caption := 'Tekenen';
    WebLabel4.Font.Charset := DEFAULT_CHARSET;
    WebLabel4.Font.Color := 4587520;
    WebLabel4.Font.Height := -16;
    WebLabel4.Font.Name := 'Tahoma';
    WebLabel4.Font.Style := [fsBold,fsItalic];
    WebLabel4.HeightPercent := 100.000000000000000000;
    WebLabel4.ParentFont := False;
    WebLabel4.WidthPercent := 100.000000000000000000;
    WebLabel5.SetParentComponent(TopPaneel);
    WebLabel5.Name := 'WebLabel5';
    WebLabel5.Left := 429;
    WebLabel5.Top := 115;
    WebLabel5.Width := 87;
    WebLabel5.Height := 19;
    WebLabel5.Caption := 'Realiseren';
    WebLabel5.Font.Charset := DEFAULT_CHARSET;
    WebLabel5.Font.Color := 4587520;
    WebLabel5.Font.Height := -16;
    WebLabel5.Font.Name := 'Tahoma';
    WebLabel5.Font.Style := [fsBold,fsItalic];
    WebLabel5.HeightPercent := 100.000000000000000000;
    WebLabel5.ParentFont := False;
    WebLabel5.WidthPercent := 100.000000000000000000;
    WebLabel6.SetParentComponent(TopPaneel);
    WebLabel6.Name := 'WebLabel6';
    WebLabel6.Left := 466;
    WebLabel6.Top := 140;
    WebLabel6.Width := 154;
    WebLabel6.Height := 19;
    WebLabel6.Caption := 'al uw bouwwerken';
    WebLabel6.Font.Charset := DEFAULT_CHARSET;
    WebLabel6.Font.Color := 4587520;
    WebLabel6.Font.Height := -16;
    WebLabel6.Font.Name := 'Tahoma';
    WebLabel6.Font.Style := [fsBold,fsItalic];
    WebLabel6.HeightPercent := 100.000000000000000000;
    WebLabel6.ParentFont := False;
    WebLabel6.WidthPercent := 100.000000000000000000;
    WebLabel7.SetParentComponent(TopPaneel);
    WebLabel7.Name := 'WebLabel7';
    WebLabel7.Left := 6;
    WebLabel7.Top := 115;
    WebLabel7.Width := 262;
    WebLabel7.Height := 19;
    WebLabel7.Caption := 'Den Braber - Service && Montage';
    WebLabel7.Font.Charset := DEFAULT_CHARSET;
    WebLabel7.Font.Color := 4587520;
    WebLabel7.Font.Height := -16;
    WebLabel7.Font.Name := 'Tahoma';
    WebLabel7.Font.Style := [fsBold];
    WebLabel7.HeightPercent := 100.000000000000000000;
    WebLabel7.ParentFont := False;
    WebLabel7.WidthPercent := 100.000000000000000000;
    WebLabel8.SetParentComponent(TopPaneel);
    WebLabel8.Name := 'WebLabel8';
    WebLabel8.Left := 29;
    WebLabel8.Top := 141;
    WebLabel8.Width := 211;
    WebLabel8.Height := 18;
    WebLabel8.Caption := 'als u het net even anders wenst';
    WebLabel8.Font.Charset := DEFAULT_CHARSET;
    WebLabel8.Font.Color := clMaroon;
    WebLabel8.Font.Height := -15;
    WebLabel8.Font.Name := 'Tahoma';
    WebLabel8.Font.Style := [fsItalic];
    WebLabel8.HeightPercent := 100.000000000000000000;
    WebLabel8.ParentFont := False;
    WebLabel8.WidthPercent := 100.000000000000000000;
    HoofdPaneel.SetParentComponent(Basis);
    HoofdPaneel.Name := 'HoofdPaneel';
    HoofdPaneel.Left := 0;
    HoofdPaneel.Top := 181;
    HoofdPaneel.Width := 945;
    HoofdPaneel.Height := 740;
    HoofdPaneel.HeightPercent := 100.000000000000000000;
    HoofdPaneel.WidthPercent := 100.000000000000000000;
    HoofdPaneel.BorderColor := clSilver;
    HoofdPaneel.BorderStyle := bsSingle;
    HoofdPaneel.ChildOrder := 4;
    HoofdPaneel.Color := clWhite;
    HoofdMenu.SetParentComponent(HoofdPaneel);
    HoofdMenu.Name := 'HoofdMenu';
    HoofdMenu.Left := 0;
    HoofdMenu.Top := 0;
    HoofdMenu.Width := 945;
    HoofdMenu.Height := 740;
    HoofdMenu.HeightPercent := 100.000000000000000000;
    HoofdMenu.WidthPercent := 100.000000000000000000;
    HoofdMenu.Align := alClient;
    HoofdMenu.BorderStyle := bsSingle;
    HoofdMenu.Font.Charset := DEFAULT_CHARSET;
    HoofdMenu.Font.Color := clMaroon;
    HoofdMenu.Font.Height := -20;
    HoofdMenu.Font.Name := 'Tahoma';
    HoofdMenu.Font.Style := [fsBold];
    HoofdMenu.TabIndex := 2;
    HoofdMenu.ParentFont := False;
    HoofdMenu.SelectedColor := 16768959;
    HoofdMenu.TabOrder := 0;
    SetEvent(HoofdMenu, Self, 'OnChange', 'HoofdMenuChange');
    Home.SetParentComponent(HoofdMenu);
    Home.Name := 'Home';
    Home.Left := 0;
    Home.Top := 20;
    Home.Width := 945;
    Home.Height := 720;
    Home.ElementClassName := 'panels';
    Home.HeightPercent := 100.000000000000000000;
    Home.WidthPercent := 100.000000000000000000;
    Home.Caption := 'Home';
    HomePanel.SetParentComponent(Home);
    HomePanel.Name := 'HomePanel';
    HomePanel.Left := 0;
    HomePanel.Top := 0;
    HomePanel.Width := 945;
    HomePanel.Height := 720;
    HomePanel.ElementClassName := 'panels';
    HomePanel.HeightPercent := 100.000000000000000000;
    HomePanel.WidthPercent := 100.000000000000000000;
    HomePanel.Align := alClient;
    HomePanel.BorderColor := clSilver;
    HomePanel.BorderStyle := bsSingle;
    HomePanel.ChildOrder := 1;
    HomePanel.Color := clWhite;
    AlgemeenContainer.SetParentComponent(HomePanel);
    AlgemeenContainer.Name := 'AlgemeenContainer';
    AlgemeenContainer.Left := 15;
    AlgemeenContainer.Top := 32;
    AlgemeenContainer.Width := 925;
    AlgemeenContainer.Height := 649;
    AlgemeenContainer.HeightPercent := 100.000000000000000000;
    AlgemeenContainer.WidthPercent := 100.000000000000000000;
    AlgemeenContainer.Font.Charset := DEFAULT_CHARSET;
    AlgemeenContainer.Font.Color := clNavy;
    AlgemeenContainer.Font.Height := -9;
    AlgemeenContainer.Font.Name := 'Tahoma';
    AlgemeenContainer.Font.Style := [];
    AlgemeenContainer.HTML.BeginUpdate;
    try
      AlgemeenContainer.HTML.Clear;
      AlgemeenContainer.HTML.Add('<I></I>');
      AlgemeenContainer.HTML.Add('<FONT color="#00273A" size="3"  face="Tahoma">Welkom bij allround klusbedrijf Den Braber Service & Montage.<br>');
      AlgemeenContainer.HTML.Add('Wij onderscheiden ons in maatwerk en kwaliteit.<br>');
      AlgemeenContainer.HTML.Add('Uw wens is onze uitdaging!<br><br></FONT>');
      AlgemeenContainer.HTML.Add('<FONT color="#00496C" size="3"  face="Tahoma">');
      AlgemeenContainer.HTML.Add('<u><b>U bent bij ons aan het juiste adres als u:</b></u><br><br>');
      AlgemeenContainer.HTML.Add('<li>advies nodig hebt voor een klus in, aan of rond uw huis.<br><br>');
      AlgemeenContainer.HTML.Add('<li>een deel van de klus zelf uitvoert maar hulp nodig hebt bij bepaalde onderdelen.<br><br>');
      AlgemeenContainer.HTML.Add('<li>compleet ontzorgd wilt worden; van tekening tot oplevering.<br><br>');
      AlgemeenContainer.HTML.Add('<li>op korte termijn geholpen wilt worden.</li><br></FONT>');
      AlgemeenContainer.HTML.Add('');
      AlgemeenContainer.HTML.Add('');
    finally
      AlgemeenContainer.HTML.EndUpdate;
    end;
    Werkzaamheden.SetParentComponent(HoofdMenu);
    Werkzaamheden.Name := 'Werkzaamheden';
    Werkzaamheden.Left := 0;
    Werkzaamheden.Top := 20;
    Werkzaamheden.Width := 945;
    Werkzaamheden.Height := 720;
    Werkzaamheden.ElementClassName := 'panels';
    Werkzaamheden.HeightPercent := 100.000000000000000000;
    Werkzaamheden.WidthPercent := 100.000000000000000000;
    Werkzaamheden.Caption := 'Werkzaamheden';
    DienstenPanel.SetParentComponent(Werkzaamheden);
    DienstenPanel.Name := 'DienstenPanel';
    DienstenPanel.Left := 0;
    DienstenPanel.Top := 0;
    DienstenPanel.Width := 945;
    DienstenPanel.Height := 720;
    DienstenPanel.ElementClassName := 'panels';
    DienstenPanel.HeightPercent := 100.000000000000000000;
    DienstenPanel.WidthPercent := 100.000000000000000000;
    DienstenPanel.Align := alClient;
    DienstenPanel.BorderColor := clSilver;
    DienstenPanel.BorderStyle := bsSingle;
    DienstenPanel.ChildOrder := 1;
    DienstenPanel.Color := clWhite;
    DienstenContainer.SetParentComponent(DienstenPanel);
    DienstenContainer.Name := 'DienstenContainer';
    DienstenContainer.Left := 15;
    DienstenContainer.Top := 32;
    DienstenContainer.Width := 925;
    DienstenContainer.Height := 649;
    DienstenContainer.HeightPercent := 100.000000000000000000;
    DienstenContainer.WidthPercent := 100.000000000000000000;
    DienstenContainer.Font.Charset := DEFAULT_CHARSET;
    DienstenContainer.Font.Color := clNavy;
    DienstenContainer.Font.Height := -9;
    DienstenContainer.Font.Name := 'Calibri';
    DienstenContainer.Font.Style := [];
    DienstenContainer.HTML.BeginUpdate;
    try
      DienstenContainer.HTML.Clear;
      DienstenContainer.HTML.Add('<I></I>');
      DienstenContainer.HTML.Add('<FONT color="#00496C" size="3"  face="Tahoma"><u><b>Wij verzorgen werkzaamheden op het gebied van:</b></u><br><br>');
      DienstenContainer.HTML.Add('<li>keukens en sanitair plaatsen.<br><br>');
      DienstenContainer.HTML.Add('<li>interne verbouwingen adviseren en uitvoeren.<br><br>');
      DienstenContainer.HTML.Add('<li>raam- en deurkozijnen plaatsen.<br><br>');
      DienstenContainer.HTML.Add('<li>pergola'#8217's ontwerpen en bouwen.<br><br>');
      DienstenContainer.HTML.Add('<li>bergingen ontwerpen, tekenen en bouwen.<br><br>');
      DienstenContainer.HTML.Add('<li>tuinhuizen naar uw idee tekenen en maken of als pakket in elkaar zetten.<br><br>');
      DienstenContainer.HTML.Add('<li>overkappingen maken.<br><br>');
      DienstenContainer.HTML.Add('<li>schuttingen en tuinpoorten zetten.<br><br>');
      DienstenContainer.HTML.Add('<li>sierstraatwerk leggen.<br><br>');
      DienstenContainer.HTML.Add('<li>zonwering en rolluiken leveren en monteren.</li><br><br></FONT>');
    finally
      DienstenContainer.HTML.EndUpdate;
    end;
    Projecten.SetParentComponent(HoofdMenu);
    Projecten.Name := 'Projecten';
    Projecten.Left := 0;
    Projecten.Top := 20;
    Projecten.Width := 945;
    Projecten.Height := 720;
    Projecten.ElementClassName := 'panels';
    Projecten.HeightPercent := 100.000000000000000000;
    Projecten.WidthPercent := 100.000000000000000000;
    Projecten.Caption := 'Projecten';
    ProjectenPanel.SetParentComponent(Projecten);
    ProjectenPanel.Name := 'ProjectenPanel';
    ProjectenPanel.Left := 0;
    ProjectenPanel.Top := 0;
    ProjectenPanel.Width := 945;
    ProjectenPanel.Height := 720;
    ProjectenPanel.ElementClassName := 'panels';
    ProjectenPanel.HeightPercent := 100.000000000000000000;
    ProjectenPanel.WidthPercent := 100.000000000000000000;
    ProjectenPanel.Align := alClient;
    ProjectenPanel.BorderColor := clSilver;
    ProjectenPanel.BorderStyle := bsSingle;
    ProjectenPanel.ChildOrder := 1;
    ProjectenPanel.Color := clWhite;
    ImageSlider.SetParentComponent(ProjectenPanel);
    ImageSlider.Name := 'ImageSlider';
    ImageSlider.Left := 207;
    ImageSlider.Top := 106;
    ImageSlider.Width := 728;
    ImageSlider.Height := 465;
    ImageSlider.HeightPercent := 100.000000000000000000;
    ImageSlider.WidthPercent := 100.000000000000000000;
    ImageSlider.Appearance.NavigationStyle := navsBullets;
    ImageSlider.Appearance.TransitionEffect := tefFlip;
    ImageSlider.Appearance.Bullets.Size := 18;
    ImageSlider.Appearance.Bullets.Opacity := 1.000000000000000000;
    ImageSlider.Appearance.Thumbnails.Opacity := 0.500000000000000000;
    ImageSlider.Appearance.Thumbnails.OpacityActive := 1.000000000000000000;
    ImageSlider.Color := clWhite;
    WebPanel1.SetParentComponent(ProjectenPanel);
    WebPanel1.Name := 'WebPanel1';
    WebPanel1.Left := 0;
    WebPanel1.Top := 0;
    WebPanel1.Width := 201;
    WebPanel1.Height := 720;
    WebPanel1.ElementClassName := 'panels';
    WebPanel1.HeightPercent := 100.000000000000000000;
    WebPanel1.WidthPercent := 100.000000000000000000;
    WebPanel1.Align := alLeft;
    WebPanel1.BorderColor := clSilver;
    WebPanel1.BorderStyle := bsSingle;
    WebPanel1.ChildOrder := 1;
    WebPanel1.Color := clWhite;
    CategorieLBL.SetParentComponent(WebPanel1);
    CategorieLBL.Name := 'CategorieLBL';
    CategorieLBL.Left := 15;
    CategorieLBL.Top := 106;
    CategorieLBL.Width := 73;
    CategorieLBL.Height := 18;
    CategorieLBL.Caption := 'Categorie';
    CategorieLBL.Font.Charset := DEFAULT_CHARSET;
    CategorieLBL.Font.Color := 4587520;
    CategorieLBL.Font.Height := -15;
    CategorieLBL.Font.Name := 'Tahoma';
    CategorieLBL.Font.Style := [fsBold];
    CategorieLBL.HeightPercent := 100.000000000000000000;
    CategorieLBL.ParentFont := False;
    CategorieLBL.WidthPercent := 100.000000000000000000;
    EffectLBL.SetParentComponent(WebPanel1);
    EffectLBL.Name := 'EffectLBL';
    EffectLBL.Left := 15;
    EffectLBL.Top := 181;
    EffectLBL.Width := 44;
    EffectLBL.Height := 18;
    EffectLBL.Caption := 'Effect';
    EffectLBL.Font.Charset := DEFAULT_CHARSET;
    EffectLBL.Font.Color := 4587520;
    EffectLBL.Font.Height := -15;
    EffectLBL.Font.Name := 'Tahoma';
    EffectLBL.Font.Style := [fsBold];
    EffectLBL.HeightPercent := 100.000000000000000000;
    EffectLBL.ParentFont := False;
    EffectLBL.WidthPercent := 100.000000000000000000;
    NavigatieLBL.SetParentComponent(WebPanel1);
    NavigatieLBL.Name := 'NavigatieLBL';
    NavigatieLBL.Left := 15;
    NavigatieLBL.Top := 256;
    NavigatieLBL.Width := 73;
    NavigatieLBL.Height := 18;
    NavigatieLBL.Caption := 'Navigatie';
    NavigatieLBL.Font.Charset := DEFAULT_CHARSET;
    NavigatieLBL.Font.Color := 4587520;
    NavigatieLBL.Font.Height := -15;
    NavigatieLBL.Font.Name := 'Tahoma';
    NavigatieLBL.Font.Style := [fsBold];
    NavigatieLBL.HeightPercent := 100.000000000000000000;
    NavigatieLBL.ParentFont := False;
    NavigatieLBL.WidthPercent := 100.000000000000000000;
    LaadFotos.SetParentComponent(WebPanel1);
    LaadFotos.Name := 'LaadFotos';
    LaadFotos.Left := 15;
    LaadFotos.Top := 326;
    LaadFotos.Width := 173;
    LaadFotos.Height := 25;
    LaadFotos.Caption := 'Laad foto'#39's';
    LaadFotos.ChildOrder := 1;
    LaadFotos.Font.Charset := DEFAULT_CHARSET;
    LaadFotos.Font.Color := clNavy;
    LaadFotos.Font.Height := -15;
    LaadFotos.Font.Name := 'Tahoma';
    LaadFotos.Font.Style := [fsBold];
    LaadFotos.HeightPercent := 100.000000000000000000;
    LaadFotos.ParentFont := False;
    LaadFotos.Visible := False;
    LaadFotos.WidthPercent := 100.000000000000000000;
    SetEvent(LaadFotos, Self, 'OnClick', 'LaadFotosClick');
    CategorieKiezer.SetParentComponent(WebPanel1);
    CategorieKiezer.Name := 'CategorieKiezer';
    CategorieKiezer.Left := 15;
    CategorieKiezer.Top := 126;
    CategorieKiezer.Width := 173;
    CategorieKiezer.Height := 26;
    CategorieKiezer.Font.Charset := DEFAULT_CHARSET;
    CategorieKiezer.Font.Color := 4587520;
    CategorieKiezer.Font.Height := -15;
    CategorieKiezer.Font.Name := 'Tahoma';
    CategorieKiezer.Font.Style := [];
    CategorieKiezer.HeightPercent := 100.000000000000000000;
    CategorieKiezer.ParentFont := False;
    CategorieKiezer.WidthPercent := 100.000000000000000000;
    SetEvent(CategorieKiezer, Self, 'OnChange', 'CategorieKiezerChange');
    CategorieKiezer.ItemIndex := -1;
    EffectKiezer.SetParentComponent(WebPanel1);
    EffectKiezer.Name := 'EffectKiezer';
    EffectKiezer.Left := 15;
    EffectKiezer.Top := 205;
    EffectKiezer.Width := 173;
    EffectKiezer.Height := 26;
    EffectKiezer.Font.Charset := DEFAULT_CHARSET;
    EffectKiezer.Font.Color := 4587520;
    EffectKiezer.Font.Height := -15;
    EffectKiezer.Font.Name := 'Tahoma';
    EffectKiezer.Font.Style := [];
    EffectKiezer.HeightPercent := 100.000000000000000000;
    EffectKiezer.ParentFont := False;
    EffectKiezer.WidthPercent := 100.000000000000000000;
    SetEvent(EffectKiezer, Self, 'OnChange', 'EffectKiezerChange');
    EffectKiezer.ItemIndex := -1;
    NavigatieKiezer.SetParentComponent(WebPanel1);
    NavigatieKiezer.Name := 'NavigatieKiezer';
    NavigatieKiezer.Left := 15;
    NavigatieKiezer.Top := 276;
    NavigatieKiezer.Width := 173;
    NavigatieKiezer.Height := 26;
    NavigatieKiezer.Font.Charset := DEFAULT_CHARSET;
    NavigatieKiezer.Font.Color := 4587520;
    NavigatieKiezer.Font.Height := -15;
    NavigatieKiezer.Font.Name := 'Tahoma';
    NavigatieKiezer.Font.Style := [];
    NavigatieKiezer.HeightPercent := 100.000000000000000000;
    NavigatieKiezer.ParentFont := False;
    NavigatieKiezer.WidthPercent := 100.000000000000000000;
    SetEvent(NavigatieKiezer, Self, 'OnChange', 'NavigatieKiezerChange');
    NavigatieKiezer.ItemIndex := -1;
    WebMemo.SetParentComponent(ProjectenPanel);
    WebMemo.Name := 'WebMemo';
    WebMemo.Left := 608;
    WebMemo.Top := 611;
    WebMemo.Width := 327;
    WebMemo.Height := 89;
    WebMemo.AutoSize := False;
    WebMemo.Font.Charset := DEFAULT_CHARSET;
    WebMemo.Font.Color := clNavy;
    WebMemo.Font.Height := -12;
    WebMemo.Font.Name := 'Tahoma';
    WebMemo.Font.Style := [];
    WebMemo.HeightPercent := 100.000000000000000000;
    WebMemo.ParentFont := False;
    WebMemo.ReadOnly := True;
    WebMemo.SelLength := 0;
    WebMemo.SelStart := 0;
    WebMemo.Visible := False;
    WebMemo.WidthPercent := 100.000000000000000000;
    Contact.SetParentComponent(HoofdMenu);
    Contact.Name := 'Contact';
    Contact.Left := 0;
    Contact.Top := 20;
    Contact.Width := 945;
    Contact.Height := 720;
    Contact.ElementClassName := 'panels';
    Contact.HeightPercent := 100.000000000000000000;
    Contact.WidthPercent := 100.000000000000000000;
    Contact.Caption := 'Contact';
    ContactPaneel.SetParentComponent(Contact);
    ContactPaneel.Name := 'ContactPaneel';
    ContactPaneel.Left := 0;
    ContactPaneel.Top := 0;
    ContactPaneel.Width := 945;
    ContactPaneel.Height := 720;
    ContactPaneel.ElementClassName := 'panels';
    ContactPaneel.HeightPercent := 100.000000000000000000;
    ContactPaneel.WidthPercent := 100.000000000000000000;
    ContactPaneel.Align := alClient;
    ContactPaneel.BorderColor := clSilver;
    ContactPaneel.BorderStyle := bsSingle;
    ContactPaneel.ChildOrder := 2;
    ContactPaneel.Color := clWhite;
    Plattegrond.SetParentComponent(ContactPaneel);
    Plattegrond.Name := 'Plattegrond';
    Plattegrond.Left := 380;
    Plattegrond.Top := 40;
    Plattegrond.Width := 550;
    Plattegrond.Height := 300;
    Plattegrond.HeightPercent := 100.000000000000000000;
    Plattegrond.WidthPercent := 100.000000000000000000;
    Plattegrond.AutoSize := True;
    Plattegrond.ChildOrder := 1;
    Plattegrond.Picture.LoadFromFile('Unit1.ContactPaneel.Plattegrond.Picture.jpg');
    ContactContainer.SetParentComponent(ContactPaneel);
    ContactContainer.Name := 'ContactContainer';
    ContactContainer.Left := 15;
    ContactContainer.Top := 32;
    ContactContainer.Width := 925;
    ContactContainer.Height := 649;
    ContactContainer.HeightPercent := 100.000000000000000000;
    ContactContainer.WidthPercent := 100.000000000000000000;
    ContactContainer.ChildOrder := 1;
    ContactContainer.HTML.BeginUpdate;
    try
      ContactContainer.HTML.Clear;
      ContactContainer.HTML.Add('<I></I>');
      ContactContainer.HTML.Add('<FONT color="#00496C"   size="3"  face="Tahoma"><u><b>Contactgegevens:</b></u><br><br>');
      ContactContainer.HTML.Add('Johan den Braber<br>');
      ContactContainer.HTML.Add('Beatrixlaan 17<br>');
      ContactContainer.HTML.Add('4694EE Scherpenisse<br><br>');
      ContactContainer.HTML.Add('Tel: 06-53479808<br>');
      ContactContainer.HTML.Add('e-mail: <a href="mailto:dmbmontage@kliksafe.nl">dmbmontage@kliksafe.nl</a><br><br>');
      ContactContainer.HTML.Add('KvK: 81962231<br>');
      ContactContainer.HTML.Add('BTW-id: NL003630394B07<br><br>');
      ContactContainer.HTML.Add('</FONT>');
      ContactContainer.HTML.Add('<FONT color="#CE0000" size="3">Contact alleen op werkdagen</FONT><br>');
      ContactContainer.HTML.Add('');
    finally
      ContactContainer.HTML.EndUpdate;
    end;
    BodemPaneel.SetParentComponent(Basis);
    BodemPaneel.Name := 'BodemPaneel';
    BodemPaneel.Left := 0;
    BodemPaneel.Top := 921;
    BodemPaneel.Width := 945;
    BodemPaneel.Height := 80;
    BodemPaneel.HeightPercent := 100.000000000000000000;
    BodemPaneel.WidthPercent := 100.000000000000000000;
    BodemPaneel.BorderColor := clSilver;
    BodemPaneel.BorderStyle := bsSingle;
    BodemPaneel.ChildOrder := 2;
    BodemPaneel.Color := clWhite;
    CopyRight.SetParentComponent(BodemPaneel);
    CopyRight.Name := 'CopyRight';
    CopyRight.Left := 300;
    CopyRight.Top := 26;
    CopyRight.Width := 317;
    CopyRight.Height := 18;
    CopyRight.Caption := 'Copyright: dMB Service && Montage (2021)';
    CopyRight.Font.Charset := DEFAULT_CHARSET;
    CopyRight.Font.Color := 4587520;
    CopyRight.Font.Height := -15;
    CopyRight.Font.Name := 'Tahoma';
    CopyRight.Font.Style := [fsBold];
    CopyRight.HeightPercent := 100.000000000000000000;
    CopyRight.ParentFont := False;
    CopyRight.WidthPercent := 100.000000000000000000;
    WebLabel9.SetParentComponent(BodemPaneel);
    WebLabel9.Name := 'WebLabel9';
    WebLabel9.Left := 763;
    WebLabel9.Top := 9;
    WebLabel9.Width := 167;
    WebLabel9.Height := 14;
    WebLabel9.Caption := 'Laatst bijgewerkt: 25-06-2021';
    WebLabel9.Font.Charset := DEFAULT_CHARSET;
    WebLabel9.Font.Color := 4587520;
    WebLabel9.Font.Height := -12;
    WebLabel9.Font.Name := 'Tahoma';
    WebLabel9.Font.Style := [];
    WebLabel9.HeightPercent := 100.000000000000000000;
    WebLabel9.ParentFont := False;
    WebLabel9.WidthPercent := 100.000000000000000000;
    WebTimer.SetParentComponent(Self);
    WebTimer.Name := 'WebTimer';
    WebTimer.Enabled := False;
    WebTimer.Left := 888;
    WebTimer.Top := 472;
    WebClientConnection1.SetParentComponent(Self);
    WebClientConnection1.Name := 'WebClientConnection1';
    WebClientConnection1.Active := False;
    WebClientConnection1.Left := 984;
    WebClientConnection1.Top := 510;
  finally
    Basis.AfterLoadDFMValues;
    TopPaneel.AfterLoadDFMValues;
    Logo.AfterLoadDFMValues;
    DeBus.AfterLoadDFMValues;
    WebLabel1.AfterLoadDFMValues;
    WebLabel2.AfterLoadDFMValues;
    WebLabel3.AfterLoadDFMValues;
    WebLabel4.AfterLoadDFMValues;
    WebLabel5.AfterLoadDFMValues;
    WebLabel6.AfterLoadDFMValues;
    WebLabel7.AfterLoadDFMValues;
    WebLabel8.AfterLoadDFMValues;
    HoofdPaneel.AfterLoadDFMValues;
    HoofdMenu.AfterLoadDFMValues;
    Home.AfterLoadDFMValues;
    HomePanel.AfterLoadDFMValues;
    AlgemeenContainer.AfterLoadDFMValues;
    Werkzaamheden.AfterLoadDFMValues;
    DienstenPanel.AfterLoadDFMValues;
    DienstenContainer.AfterLoadDFMValues;
    Projecten.AfterLoadDFMValues;
    ProjectenPanel.AfterLoadDFMValues;
    ImageSlider.AfterLoadDFMValues;
    WebPanel1.AfterLoadDFMValues;
    CategorieLBL.AfterLoadDFMValues;
    EffectLBL.AfterLoadDFMValues;
    NavigatieLBL.AfterLoadDFMValues;
    LaadFotos.AfterLoadDFMValues;
    CategorieKiezer.AfterLoadDFMValues;
    EffectKiezer.AfterLoadDFMValues;
    NavigatieKiezer.AfterLoadDFMValues;
    WebMemo.AfterLoadDFMValues;
    Contact.AfterLoadDFMValues;
    ContactPaneel.AfterLoadDFMValues;
    Plattegrond.AfterLoadDFMValues;
    ContactContainer.AfterLoadDFMValues;
    BodemPaneel.AfterLoadDFMValues;
    CopyRight.AfterLoadDFMValues;
    WebLabel9.AfterLoadDFMValues;
    WebTimer.AfterLoadDFMValues;
    WebClientConnection1.AfterLoadDFMValues;
  end;
end;

end.
